import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";

import "./App.css";
import { ChakraProvider, Box } from "@chakra-ui/react";
import theme from "./Theme/theme";
import { Route, Routes, Navigate } from "react-router-dom";
import LoginPage from "./Components/Login";
import Home from "./Components/Home";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
