import {
  Box,
  Button,
  Flex,
  VStack,
  Text,
  Heading,
  Image,
} from "@chakra-ui/react";
import React from "react";
import { PopupButton } from "react-calendly";

function Hero() {
  return (
    <Flex
      flexDir={{ base: "column", md: "row" }}
      justifyContent={{ base: "center", md: "space-between" }}
      alignItems={"center"}
    >
      <Box>
        <Box
          display={"flex"}
          flexDir={"column"}
          justify={"flex-start"}
          gap={12}
        >
          <Heading size={"2xl"} maxW={{ base: "90%", md: "630px" }}>
            Quick and expowering solutions for your company
          </Heading>
          <Text maxW={{ base: "90%", md: "700px" }} fontSize={"18px"}>
            Empowering Businesses with AI generated solutions for success and
            growth. Streamline workflows, foster collaboration, and unlock your
            true potential with EZY-AI Chatbots. Experience the future of work
            today.
          </Text>
          <Box
            display={"flex"}
            flexDir={{ base: "column", md: "row" }}
            justify={"space-around"}
            gap={8}
          >
            <Button colorScheme="blue">Get Started FREE</Button>
            <PopupButton
              url="https://calendly.com/ezy-5nk/15min?month=2023-07"
              /*
               * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
               * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
               */
              rootElement={document.getElementById("root")}
              text="Book a Demo"
            />{" "}
          </Box>
        </Box>
      </Box>
      <Box pt={{ base: 0, md: 12 }}>
        <Image src="/hero.png"></Image>
      </Box>
    </Flex>
  );
}

export default Hero;
