import { Box, Button, Flex, Heading, Text, Image } from "@chakra-ui/react";
import React from "react";

function Functionality() {
  return (
    <>
      <Flex
        flexDir={{ base: "column", md: "row" }}
        justify={{ base: "center", md: "space-between" }}
        alignItems={"center"}
        mt={12}
      >
        <Box
          display={"flex"}
          flexDir={"column"}
          justifyContent={"center"}
          alignItems={{ base: "center", md: "flex-start" }}
          mt={12}
          gap={8}
        >
          <Heading size={"lg"} color="blue">
            1st Functionality
          </Heading>
          <Heading size={"xl"} textAlign={{ base: "center", md: "flexStart" }}>
            Effortless Task Management
          </Heading>
          <Text maxW={"530px"} textAlign={{ base: "center", md: "flexStart" }}>
            Simplify project management with our intuitive task tracking system.
            Assign tasks, set deadlines, and track progress effortlessly.
          </Text>
          {/* <Button colorScheme="blue">Streamline Tasks Now</Button> */}
        </Box>
        <Box padding={12}>
          <Image src="/1st.png"></Image>
        </Box>
      </Flex>
      <Flex
        flexDir={{ base: "column", md: "row" }}
        justify={{ base: "center", md: "space-between" }}
        alignItems={"center"}
        mt={12}
      >
        <Box padding={12}>
          <Image src="/1st.png"></Image>
        </Box>
        <Box
          display={"flex"}
          flexDir={"column"}
          justifyContent={"center"}
          alignItems={{ base: "center", md: "flex-start" }}
          mt={12}
          gap={8}
        >
          <Heading size={"lg"} color="blue">
            2nd Functionality
          </Heading>
          <Heading size={"xl"} textAlign={{ base: "center", md: "flexStart" }}>
            Seamless Communication
          </Heading>
          <Text maxW={"530px"} textAlign={{ base: "center", md: "flexStart" }}>
            Facilitate seamless communication and collaboration with integrated
            messaging and real-time updates. Stay connected and never miss.
          </Text>
          {/* <Button colorScheme="blue">Streamline Tasks Now</Button> */}
        </Box>
      </Flex>
      <Flex
        flexDir={{ base: "column", md: "row" }}
        justify={{ base: "center", md: "space-between" }}
        alignItems={"center"}
        mt={12}
      >
        <Box
          display={"flex"}
          flexDir={"column"}
          justifyContent={"center"}
          alignItems={{ base: "center", md: "flex-start" }}
          mt={12}
          gap={8}
        >
          <Heading size={"lg"} color="blue">
            3rd Functionality
          </Heading>
          <Heading size={"xl"} textAlign={{ base: "center", md: "flexStart" }}>
            Powerful Analytics
          </Heading>
          <Text maxW={"530px"} textAlign={{ base: "center", md: "flexStart" }}>
            Gain valuable insights into your business with our powerful
            analytics tools. Track key metrics, visualize data, and make
            informed decisions.{" "}
          </Text>
          {/* <Button colorScheme="blue">Streamline Tasks Now</Button> */}
        </Box>
        <Box padding={12}>
          <Image src="/1st.png"></Image>
        </Box>
      </Flex>
    </>
  );
}

export default Functionality;
