import React from "react";
import { Box, Button, Heading } from "@chakra-ui/react";
import Navbar from "./Navbar";
import { FcGoogle } from "react-icons/fc";
import Footer from "./Footer";
import axios from "axios";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import MobileNav from "./MobileNav";

export default function Login() {
  const logout = () => {
    localStorage.removeItem("name");
    localStorage.removeItem("pfp");
    window.location.reload();
  };
  return (
    <Box maxW={"1440px"} margin={"0 auto"} padding={{ base: 4, md: 8, lg: 12 }}>
      <Navbar />
      <MobileNav />
      <Box
        backgroundColor={"#ffffff"}
        boxShadow={"0 0 1px black;"}
        padding={8}
        borderRadius={"5px"}
        maxW={"450px"}
        margin={"0 auto"}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={8}
      >
        <Box>
          <Heading size={"xl"}>
            Welcome to{" "}
            <span style={{ color: "rgb(49, 130, 206)" }}>EZY-AI</span>
          </Heading>
        </Box>
        <Box
          display={"flex"}
          flexDir={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={8}
        >
          {localStorage.getItem("name") ? (
            <Button variant={"solid"} onClick={logout}>
              Logout from Google
            </Button>
          ) : (
            <>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  const data = jwtDecode(credentialResponse.credential);
                  console.log(data);
                  localStorage.setItem("name", data.name);
                  localStorage.setItem("pfp", data.picture);
                  window.location.reload();
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
