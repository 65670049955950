import { HStack, Box, Heading, Text, Icon, Stack } from "@chakra-ui/react";
import { BiTimeFive, BiSupport } from "react-icons/bi";
import { AiOutlineSafety } from "react-icons/ai";
import { PiSuitcaseSimpleLight, PiChartLineUp } from "react-icons/pi";

import React from "react";

export default function Services() {
  return (
    <Stack gap={8} mt={12} direction={{ base: "column", md: "row" }}>
      {data.map((d, idx) => (
        <Box
          minH={"235px"}
          maxW={"250px"}
          margin={"0 auto"}
          key={idx}
          display={"flex"}
          flexDir={"column"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          backgroundColor={"#FFFFFF"}
          boxShadow={"0 0 3px rgba(0, 0, 0, 0.15);"}
          borderRadius={"5px"}
          padding={8}
          gap={4}
        >
          <Heading size={"md"}>{d.heading}</Heading>
          <Text>{d.subtext}</Text>
          {/* <Heading>{d.icon}</Heading> */}
          <Icon as={d.icon} boxSize={6} />
        </Box>
      ))}
    </Stack>
  );
}

const data = [
  {
    heading: "Increased Efficieny",
    subtext: "Streamline your workflow and save time.",
    icon: BiTimeFive,
  },
  {
    heading: "Improved cooperation",
    subtext: "Foster teamwork and communcation.",
    icon: PiSuitcaseSimpleLight,
  },
  {
    heading: "Scalibility",
    subtext: "Adapt to growing business needs seamlessly",
    icon: PiChartLineUp,
  },
  {
    heading: "Data Security",
    subtext: "Ensure the safety and privacy of your vulnerable data.",
    icon: AiOutlineSafety,
  },
  {
    heading: "24/7 Support",
    subtext: "Provide round-the-clock assistance to your customers.",
    icon: BiSupport,
  },
];
