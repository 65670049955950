import { Box, Heading, Image, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Link } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { PopupButton } from "react-calendly";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <Box
      display={{ base: "none", md: "flex" }}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box ml={{ base: "0px", lg: "-44px" }}>
        <Link href="/">
          <Image
            cursor={"pointer"}
            src="/logo.svg"
            maxW={"150px"}
            maxH={"150px"}
          ></Image>
        </Link>
      </Box>
      {/* <Box display={"flex"} gap={20}>
        <Link
          href="/"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
        >
          <Text fontSize={"20px"}>Product</Text>
          <ChevronDownIcon boxSize={6} />
        </Link>
        <Link
          href="/"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
        >
          <Text fontSize={"20px"}>Solution</Text>
          <ChevronDownIcon boxSize={6} />
        </Link>
        <Link
          href="/"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
        >
          <Text fontSize={"20px"}>Resources</Text>
          <ChevronDownIcon boxSize={6} />
        </Link>
        <Link
          href="/"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
        >
          <Text fontSize={"20px"}>Pricing</Text>
        </Link>
      </Box> */}
      <Box
        display={"flex"}
        gap={8}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {localStorage.getItem("name") ? (
          <Box
            display={"flex"}
            flexDir={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={4}
            backgroundColor={"gray.100"}
            borderRadius={"5px"}
            px={4}
            py={2}
          >
            <Heading fontSize={"medium"}>
              {localStorage.getItem("name")}
            </Heading>
            <Image
              cursor={"pointer"}
              onClick={() => navigate("/login")}
              borderRadius={"50%"}
              w={50}
              h={50}
              src={localStorage.getItem("pfp")}
            ></Image>
          </Box>
        ) : (
          <Button
            colorScheme="blue"
            variant={"ghost"}
            onClick={handleLoginClick}
          >
            <Text fontSize={"20px"}>Login</Text>
          </Button>
        )}
        <PopupButton
          url="https://calendly.com/ezy-5nk/15min?month=2023-07"
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
          text="Get Started FREE"
          styles={{
            backgroundColor: "#3182ce",
            padding: "12px",
            color: "white",
            borderRadius: "5px",
          }}
        />{" "}
      </Box>
    </Box>
  );
}

export default Navbar;
