import { Box, Button, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function Experience() {
  const navigate = useNavigate();

  return (
    <Box
      backgroundColor={"rgba(217, 217, 217, 0.1)"}
      display={"flex"}
      flexDir={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={12}
      py={12}
    >
      <Heading maxW={"750px"} textAlign={"center"}>
        Experience the Future of Work Today
      </Heading>
      <Text maxW={"800px"} textAlign={"center"}>
        Take the first step towards transforming your business. Sign up for our
        free trial and experience the power of EZY-Chatbot for yourself.{" "}
      </Text>
      <Button
        colorScheme="blue"
        margin={{ base: "0 auto", md: "0" }}
        onClick={() => navigate("/login")}
      >
        Start Your Free Trial Today{" "}
      </Button>
    </Box>
  );
}

export default Experience;
