import { Flex, Box, Image, Heading, Text, Button } from "@chakra-ui/react";
import React from "react";

function Flexibilty() {
  return (
    <Flex
      flexDir={{ base: "column", md: "row" }}
      mt={12}
      justify={"space-between"}
      alignItems={"center"}
    >
      <Box
        display={"flex"}
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        mt={4}
        gap={8}
      >
        <Heading size={"lg"} textAlign={{ base: "center", md: "flexStart" }}>
          Limitless flexibilty on different platforms
        </Heading>
        <Heading size={"xl"} textAlign={{ base: "center", md: "flexStart" }}>
          Seamless Communication
        </Heading>
        <Text maxW={"530px"} textAlign={{ base: "center", md: "flexStart" }}>
          Connect, Collaborate, and automate with EZY chatbot's extensive
          library of inegrations. Our platform seamlessly integrates with
          popular software, social media to empower your workflow and to
          maximize productivity
        </Text>
        {/* <Button colorScheme="blue" margin={{ base: "0 auto", md: "0" }}>
          Explore Our Integrations Today
        </Button> */}
      </Box>
      <Box padding={12}>
        <Image src="/CallPageIntegrations.svg"></Image>
      </Box>
    </Flex>
  );
}

export default Flexibilty;
