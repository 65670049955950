import React from "react";
import { Box } from "@chakra-ui/react";
import Navbar from "./Navbar";
import MobileNav from "./MobileNav";
import Hero from "./Hero";
import Services from "./Services";
import Chatbot from "./Chatbot";
// import Functionality from "./Functionality";
import Functionality from "./Functionality";
import Flexibilty from "./Flexibilty";
import Experience from "./Experience";
import Footer from "./Footer";

export default function Home() {
  return (
    <Box maxW={"1440px"} margin={"0 auto"} padding={{ base: 4, md: 8, lg: 12 }}>
      <Navbar />
      <MobileNav />
      <Hero />
      <Services />
      <Chatbot />
      <Functionality />
      <Flexibilty />
      <Experience />
      <Footer />
    </Box>
  );
}
