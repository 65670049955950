import { Box, Link, Image, Button, Text } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import React from "react";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { PopupButton } from "react-calendly";
import { useNavigate } from "react-router-dom";

function MobileNav() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const navigate = useNavigate();

  return (
    <Box
      display={{ base: "flex", md: "none" }}
      padding={2}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <HamburgerIcon ref={btnRef} onClick={onOpen} />
      <Link href="/">
        <Image cursor={"pointer"} src="/logo.svg" w={75} h={75}></Image>
      </Link>
      <Button
        colorScheme="blue"
        variant={"ghost"}
        onClick={() => navigate("/login")}
      >
        <Text fontSize={"12px"}>Login</Text>
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>EZY-AI</DrawerHeader>

          <DrawerBody>
            <Box gap={20}>
              <Link
                href="/"
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                gap={2}
              >
                <Text fontSize={"20px"}>Product</Text>
                {/* <ChevronDownIcon boxSize={6} /> */}
              </Link>
              <Link
                href="/"
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                gap={2}
              >
                <Text fontSize={"20px"}>Solution</Text>
                {/* <ChevronDownIcon boxSize={6} /> */}
              </Link>
              <Link
                href="/"
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                gap={2}
              >
                <Text fontSize={"20px"}>Resources</Text>
                {/* <ChevronDownIcon boxSize={6} /> */}
              </Link>
              <Link
                href="/"
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                gap={2}
              >
                <Text fontSize={"20px"}>Pricing</Text>
                {/* <ChevronDownIcon boxSize={6} /> */}
              </Link>
              <PopupButton
                url="https://calendly.com/ezy-5nk/15min?month=2023-07"
                /*
                 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                 */
                rootElement={document.getElementById("root")}
                text="Get Started FREE"
                styles={{
                  marginTop: "12px",
                  backgroundColor: "#3182ce",
                  padding: "8px",
                  color: "white",
                  borderRadius: "5px",
                }}
              />{" "}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

export default MobileNav;
