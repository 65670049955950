import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";

function Chatbot() {
  return (
    <Box
      display={"flex"}
      flexDir={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={6}
      mt={12}
    >
      <Heading maxW={"750px"} textAlign={"center"}>
        Invest your time freely while your ChatBot is serving your company
      </Heading>
      <Text maxW={"800px"} textAlign={"center"} fontSize={"18px"}>
        EZY ChatBot´s are taking care of all of your customers across the world,
        with it´s 24/7 availability and ability to give service in 52+ different
        languages.
      </Text>
    </Box>
  );
}

export default Chatbot;
