import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: `'Inter'`,
    body: `'Inter'`,
  },
});

export default theme;
