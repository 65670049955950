import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

function Footer() {
  return (
    <Box
      display={"flex"}
      flexDir={{ base: "column", md: "row" }}
      justifyContent={"space-between"}
      alignItems={"flex-start"}
      mt={24}
      py={12}
    >
      <Box>
        <Text maxW={{ base: "70%", md: "200px" }} fontSize={20}>
          Transforming Industries through Innovative Technology
        </Text>
      </Box>
      <Box
        display={"flex"}
        flexDir={"column"}
        justify={"center"}
        alignItems={"flex-staer"}
        mt={{ base: 4, md: 0 }}
        gap={4}
      >
        <Heading mb={4}>About Us</Heading>
        <Link top={"/"}>About</Link>
        <Link top={"/"}>Blog</Link>
        <Link top={"/"}>Team</Link>
        <Link top={"/"}>Contact</Link>
      </Box>
      <Box
        display={"flex"}
        flexDir={"column"}
        justify={"center"}
        alignItems={"flex-staer"}
        mt={{ base: 4, md: 0 }}
        gap={4}
      >
        <Heading mb={{ base: 0, md: 4 }}>Company</Heading>
        <Link top={"/"}>Privacy</Link>
        <Link top={"/"}>Support</Link>
        <Link top={"/"}>Help Desk</Link>
        <Link top={"/"}>FAQ</Link>
      </Box>
      <Box
        display={"flex"}
        flexDir={"column"}
        justify={"center"}
        alignItems={"flex-start"}
        mt={{ base: 4, md: 0 }}
        gap={4}
      >
        <Heading mb={4}>Blog</Heading>
        <Flex
          gap={4}
          padding={4}
          boxShadow={"0 2px 4px rgba(0, 0, 0, 0.15);"}
          maxW={{ base: "90%", md: "320px" }}
        >
          <Box>
            <Image src="/webinar.png"></Image>
          </Box>
          <Box
            display={"flex"}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"flex-start"}
          >
            <Text fontSize={"20px"} maxW={"260px"}>
              Webinar: The Future of Digital Transformation in Business
            </Text>
            <Button colorScheme="blue" variant={"ghost"}>
              Read More
            </Button>
          </Box>
        </Flex>
        <Flex
          gap={4}
          padding={4}
          boxShadow={"0 2px 4px rgba(0, 0, 0, 0.15);"}
          maxW={{ base: "90%", md: "320px" }}
        >
          <Box>
            <Image src="/webinar.png"></Image>
          </Box>
          <Box
            display={"flex"}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"flex-start"}
          >
            <Text fontSize={"20px"} maxW={"260px"}>
              Webinar: The Future of Digital Transformation in Business
            </Text>
            <Button colorScheme="blue" variant={"ghost"}>
              Read More
            </Button>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default Footer;
